<template>
  <div class="login-container">
    <el-card shadow="hover" size="small" style="min-width: 280px;padding:32px 24px 16px 24px">
      <el-form :model="loginForm" :rules="loginRules" class="login-form" ref="loginForm"  auto-complete="on">
        <div class="title-container">
          <h3 class="title">Vstar域动骑行</h3>
          <div style="font-size: 24px;font-weight: bold;margin-top: 8px;display: flex;align-items: center;justify-content: center;margin-bottom: 32px">
            <div style="width: 8px;height: 8px;border-radius: 50%;background: #fff"/>
            <div style="margin: 0 16px">管 理 系 统</div>
            <div style="width: 8px;height: 8px;border-radius: 50%;background: #fff"/>
          </div>
        </div>
            <el-form-item prop="username">
                <el-input
                        ref="username"
                        v-model="loginForm.username"
                        placeholder="Username"
                        name="username"
                        type="text"
                        tabindex="1"
                        auto-complete="on"
                />
            </el-form-item>

            <el-form-item prop="password">
                <el-input
                        :key="passwordType"
                        ref="password"
                        v-model="loginForm.password"
                        :type="passwordType"
                        placeholder="Password"
                        name="password"
                        tabindex="2"
                        auto-complete="on"
                        show-password
                />
            </el-form-item>
            <el-button :loading="loading" type="primary" class="submit-btn" style="width:100%;margin-bottom:30px;" @click="handleLogin">登录</el-button>
        <div style="font-size: 10px;margin-top: 24px;text-align: center;color: #fff">@2021BanbanTechnology</div>
      </el-form>
    </el-card>
    <div style="position: fixed;bottom: 24px;color: rgba(255,255,255,0.5);width: 100vw;text-align: center;font-weight: inherit">
      <!--      <div style="font-size: 16px;margin-bottom: 8px">Shenzhen Banban Technology Co., Ltd</div>-->
      <div style="font-size: 10px;">
        <span style="margin: 0 16px;">深圳市班班科技有限公司</span>
<!--        <span style="margin: 0 16px;">公网安备11000002000001号</span>-->
<!--        <span style="margin: 0 16px;">ICP证030173号</span>-->
        <span style="margin: 0 16px;">©2021BanBanTechnology</span>
      </div>
    </div>
  </div>
</template>F

<script>
    // import { validUsername } from '@/utils/validate'

    export default {
        name: 'Login',
        data() {
            // const validateUsername = (rule, value, callback) => {
            //     if (!value.length<4) {
            //         callback(new Error('Please enter the correct user name'))
            //     } else {
            //         callback()
            //     }
            // }
            // const validatePassword = (rule, value, callback) => {
            //     if (value.length < 6) {
            //         callback(new Error('The password can not be less than 6 digits'))
            //     } else {
            //         callback()
            //     }
            // }
            return {
                loginForm: {
                    // username: 'admin',
                    // password: '123456'
                    username: '',
                    password: ''
                },
                loginRules: {
                    username: [{ required: true, trigger: 'blur', }],
                    password: [{ required: true, trigger: 'blur', }]
                },
                loading: false,
                passwordType: 'password',
                redirect: undefined
            }
        },
        watch: {
            $route: {
                handler: function(route) {
                    this.redirect = route.query && route.query.redirect
                },
                immediate: true
            }
        },
        mounted () {
          let that = this
          document.onkeydown = () => {
            let key = window.event.keyCode
            if (key === 13) {
              that.handleLogin()
            }
          }
        },
        methods: {
            // showPwd() {
            //     if (this.passwordType === 'password') {
            //         this.passwordType = ''
            //     } else {
            //         this.passwordType = 'password'
            //     }
            //     this.$nextTick(() => {
            //         this.$refs.password.focus()
            //     })
            // },
            handleLogin() {
                this.$refs.loginForm.validate(valid => {
                    console.log('valid:',valid)
                    if (valid) {
                        this.loading = true
                        this.$store.dispatch('user/login', this.loginForm).then(() => {
                            this.$router.push({ path: this.redirect || '/' })
                            this.loading = false
                        }).catch(() => {
                            this.loading = false
                        })
                    } else {
                        console.log('error submit!!')
                        return false
                    }
                })
            }
        }
    }
</script>
<style scoped>

.login-container {
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: url(~@/assets/images/login-bg.jpg) no-repeat;
  background-size: cover;
}


/deep/ .el-card {
  border: 1px solid #fff;
  backdrop-filter: blur(10px) brightness(100%);
  background: rgba(255, 255, 255, 0.4);
  margin-right: 420px;
}

.submit-btn {
  width: 100%;
  margin-top: 40px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
}

.login-form {
  text-align: center;
  color: white;
}

.title {
  margin: 32px 0 12px 0;
  font-size: 24px;
}

</style>
